<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<div class="container">
  <div class="d-flex flex-column flex-md-row mb-3 mb-md-0">
    <div class="col-md-3">
      <h2>Events Archived</h2>
    </div>
    <div class="col-md-5">
      <input [(ngModel)]="search"
             nbInput class="w-100"
             nbTooltip="Tap here to search a event "
             nbTooltipStatus="primary" placeholder="Search Event" type="text">

    </div>

    <div class="btn-toolbar col-md-6" role="toolbar">
      <a (click)="exportAsXLSX()" aria-current="page" class=" mx-1 align-self-center active"
         id="tooltipExport" nbTooltip="Export data to excel file "
         nbTooltipStatus="primary" nbButton
         status="success"> <i class="fas fa-file-excel text-white"></i>
      </a>
    </div>
  </div>

  <div *ngIf="!dataEvents || dataEvents.length === 0" align="center">
    <nb-alert class="w-100 mt-3" outline="danger">No Data Found.</nb-alert>
  </div>
  <div *ngIf="(dataEvents | filter : search).length === 0" [hidden]="dataEvents.length === 0"
       class="">
    <nb-alert class="col-lg-12 mt-3" fullWidth outline="danger">The item searched is not found. Please make sure
      while typing your data
    </nb-alert>
  </div>
  <div class="row mt-3">
    <div class="row col-md-12">
      <nb-card
        *ngFor="let event of dataEvents| filter : search | paginate : {itemsPerPage:pageSize, currentPage: current,totalItems: dataEvents?.length}"
        class="col-md-4 form-group">

        <div *ngIf="event.statusEvent === 'Scheduled'">
          <nb-badge position="top right" status="success" text={{event.statusEvent}}></nb-badge>
        </div>
        <div *ngIf="event.statusEvent === 'Ongoing'">
          <nb-badge position="top right" status="info" text={{event.statusEvent}}></nb-badge>
        </div>
        <div *ngIf="event.statusEvent === 'Completed'">
          <nb-badge position="top right" status="basic" text={{event.statusEvent}}></nb-badge>
        </div>
        <div *ngIf="event.statusEvent === 'Postponed'">
          <nb-badge position="top right" status="warning" text={{event.statusEvent}}></nb-badge>
        </div>
        <div *ngIf="event.statusEvent === 'Cancelled'">
          <nb-badge position="top right" status="danger" text={{event.statusEvent}}></nb-badge>
        </div>

        <!-- Image de l'événement -->
        <div class="w-100" style="margin-top: 30px;">
          <img src="{{event.image}}" alt="Event Image" style="width: 600px; height: 200px;" class="img-fluid">
        </div>

        <div class="row mt-4">
          <div class="row">
            <div class="col-md-7 mx-2">
              <nb-user [name]="event.user.username" [picture]="event.user.userProfileImage" size="medium"></nb-user>
            </div>

            <div class="col-md-3" align="right">
              <button [matMenuTriggerFor]="menu" aria-expanded="false" aria-haspopup="true"
                      aria-label="Example icon-button with a menu" class="float-right"
                      mat-icon-button nbButton
                      nbTooltip="Click here to see advanced menu"
                      nbTooltipStatus="primary"
                      shape="round"
                      size="small"
                      status="basic">
                <nb-icon icon="more-horizontal-outline"></nb-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <a (click)="onRestoreConfirm(event,event.id)"  fullWidth nbButton>
                    <span class="text-info"><nb-icon icon="refresh-outline"></nb-icon></span>Restore
                  </a>
                </button>

              </mat-menu>

            </div>
            <div class="col-md-12 pt-4 mx-3">
              <p>
                <nb-icon class="text-primary" icon="file-outline" nbTooltip="Event title" style="margin-right: 5px;"></nb-icon>
                <b>{{event.title}}</b>
              </p>
              <p>
                <nb-icon class="text-primary" icon="clock-outline" nbTooltip="Event time" style="margin-right: 5px;"></nb-icon>
                <b>{{event.timeOfEvent}}</b>
                <b>{{event.dateOfEvent | date : ' - EEEE, d MMMM yyyy'}}</b>
              </p>
              <p>
                <nb-icon class="text-primary" icon="pin-outline" nbTooltip="Event location" style="margin-right: 5px;"></nb-icon>
                <b>{{event.address}}, {{event.country}}</b>
              </p>
              <p>
                <nb-icon class="text-primary" icon="info-outline" nbTooltip="Event type" style="margin-right: 5px;"></nb-icon>
                <b>{{ event.type === 'Other' ? event.otherType : event.type }}</b>
              </p>
            </div>



          </div>


        </div>


      </nb-card>

    </div>
  </div>

      <pagination-controls (pageChange)="current = $event" align="right"></pagination-controls>

</div>

