<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="content">
    <div class="container">
  <nb-card-header>
    <div class="row">
      <div class="col-md-3">
        <h3>Deals Archived</h3>
      </div>
      <div class="col-md-8">
        <div class="row">
            <input [(ngModel)]="search"
             class="w-75 mx-2" nbInput fieldSize="large"
             nbTooltip="Tap here to search a deal" nbTooltipStatus="primary" placeholder="Search deal ..."
             type="text">
        </div>
      </div>
      <div class="col-md-1 pt-1">
          <div>

            <a (click)="exportAsXLSX()" aria-current="page"
               nbButton
               class="mx-1"
               id="tooltipExport" nbTooltip="Click here to export data in excel"
               nbTooltipStatus="primary"
               status="success">
              <i class="fas fa-file-excel text-white"></i>
            </a>

          </div>

      </div>
    </div>
  </nb-card-header>


  <div class="row">
  <div class="col-lg-12">

    <table class="mb-0 table table-hover mt-3">
        <thead>
        <tr>
          <th class="align-middle bt-0">Visit Ref</th>
          <th class="align-middle bt-0">Deal Name</th>
          <th class="align-middle bt-0">Deal Type</th>
          <th class="align-middle bt-0">Deal Value</th>
          <th class="align-middle bt-0">Win Chance</th>
          <th class="align-middle bt-0">Employee</th>
          <th class="align-middle bt-0">Status</th>
          <th class="align-middle bt-0 text-right">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let deal of dataDeals | filter : search | paginate : {itemsPerPage:pageSize, currentPage: current,totalItems: total}">
          <td class="align-middle">
            <p>{{deal?.visit?.refVisit}}</p>
          </td>
          <td class="align-middle">
            <div><p>{{deal.dealName}}</p></div>
          <td class="align-middle"><p>{{deal.dealType}}</p></td>
          <td class="align-middle">

            <p>{{deal.dealValue}}</p>
          </td>
          <td class="align-middle">
            <p>{{deal.winChance}}</p>
          </td>
          <td class="align-middle">
            <p>{{deal?.visit?.employee?.employeeName}}</p>
          </td>


          <td class="align-middle">
            <p *ngIf="deal.dealStatus === 'paused'"
               class="mb-2 badge badge-warning badge-pill">{{deal.dealStatus}}</p>
            <p *ngIf="deal.dealStatus === 'Done'"
               class="mb-2 badge badge-success badge-pill">{{deal.dealStatus}}</p>
            <p *ngIf="deal.dealStatus === 'In Progress'"
               class="mb-2 badge badge-secondary badge-pill">{{deal.dealStatus}}</p>
            <p *ngIf="deal.dealStatus === 'Canceled'"
               class="mb-2 badge badge-danger badge-pill">{{deal.dealStatus}}</p>
          </td>
          <td class="align-middle text-right">
            <div class="btn-group">
              <button [matMenuTriggerFor]="menu" aria-expanded="false" aria-haspopup="true"
                      aria-label="Example icon-button with a menu" mat-icon-button
                      nbButton nbTooltip="Click here to see advanced menu"
                      nbTooltipStatus="primary"
                      shape="round"
                      size="small"
                      status="basic">
                <nb-icon icon="more-horizontal-outline"></nb-icon>
              </button>
              <mat-menu #menu="matMenu" class="bg-white">
                 <li class="list-unstyled text-dark mx-2 mt-2 mb-2">
                    <a (click)="onRestoreConfirm(deal,deal.id)" fullWidth nbButton status="basic">
                      <nb-icon icon="archive-outline"></nb-icon> Restore
                    </a>
                  </li>
              </mat-menu>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    <div class="mt-4 d-flex justify-content-end">
      <nb-card class="" style="height: 35px;margin-left: 0; border-radius: 25px;">
        <pagination-controls class="pt-1" (pageChange)="current = $event"></pagination-controls>
      </nb-card>
    </div>
    </div>

  </div></div>
</div>
