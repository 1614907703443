<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="content">
  <div class="container">
    <nb-card-header>
      <div class="row">
        <div class="col-md-3">
          <h3>Prospects</h3>
        </div>

        <div class="col-md-6">
          <div class="row">
            <input [(ngModel)]="search" [disabled]="dataCustomers.length === 0"
                   class="w-75" nbInput
                   nbTooltip="Tap here to search your prospect" nbTooltipStatus="primary" placeholder="search prospect"
                   type="text">
          </div>
        </div>
        <div class="col-md-3">
          <div>
            <a [disabled]="dataCustomers.length === 0" [routerLink]="['/crm/prospects']" nbButton
               nbTooltip="Visualize data on dimensions grid 2x2" nbTooltipStatus="primary" status="primary">
              <nb-icon icon="grid-outline"></nb-icon>
            </a>&nbsp;

            <a [disabled]="dataCustomers.length === 0" [routerLink]="['/crm/list-prospects']" nbButton
               nbTooltip="Visualize data on list " nbTooltipStatus="primary" status="basic">
              <nb-icon icon="list-outline"></nb-icon>
            </a>&nbsp;
            <button [disabled]="dataCustomers.length === 0" (click)="exportAsXLSX()"
                    align="right" class="mx-1"
                    nbButton nbTooltip="Click here to export data to Excel" nbTooltipStatus="primary"
                    status="success">
              <i class="fas fa-file-excel text-white"></i>
            </button>

            <button [routerLink]="['/crm/create-prospect']" class="mx-1" nbButton

                    nbTooltip="Click here to create prospect" nbTooltipStatus="primary"
                    status="primary">
              <nb-icon icon="plus-outline"></nb-icon>
              Add
            </button>

          </div>
        </div>
      </div>

    </nb-card-header>
    <div *ngIf="!dataCustomers || dataCustomers.length === 0" align="center">
      <nb-alert class="w-100 mt-3" outline="danger">No Data Found.</nb-alert>
    </div>

    <div *ngIf="(dataCustomers | filter : search).length === 0" [hidden]="dataCustomers.length === 0"
         class="">
      <nb-alert class="col-lg-12 mt-3" fullWidth outline="danger">The item searched is not found. Please make sure
        while typing your data
      </nb-alert>
    </div>

    <div class="row mt-3">
      <div class="col-md-3">
        <nb-form-field>
          <nb-icon icon="flip-outline" nbPrefix></nb-icon>
          <nb-select
                     class="w-75 mx-2" nbTooltip="Tap to sort by name "
                     nbTooltipStatus="primary"
                     placeholder="&nbsp; Name">
            <nb-option (click)="getAllProspects()" value="All"> &nbsp;&nbsp;All</nb-option>
            <nb-option (click)="getProspectsAsc()" value="1"> &nbsp;&nbsp; Names (A-Z)</nb-option>
            <nb-option (click)="getProspectsDesc()" value="2">&nbsp;&nbsp; Names (Z-A)</nb-option>
          </nb-select>
        </nb-form-field>

        <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select
                     class="w-75 mx-2 mt-3" nbTooltip="Tap to sort by date "
                     nbTooltipStatus="primary"  placeholder="&nbsp; Date">
            <nb-option (click)="getAllProspects()" value="All">&nbsp;&nbsp;All</nb-option>
            <nb-option (click)="getProspectsCreatedDesc()" value="1">&nbsp;&nbsp;Recently added</nb-option>
            <nb-option (click)="getAllProspects()" value="2">&nbsp;&nbsp;Old</nb-option>
          </nb-select>
        </nb-form-field>

        <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="getProspectsByAddress($event,'propertyCountry')"
                     class="w-75 mt-3 mx-2" placeholder="&nbsp; Country">
            <nb-option (click)="getAllProspects()" value="All"> &nbsp;&nbsp;All</nb-option>
            <nb-option *ngFor="let country of countries"
                       value="{{country.country_name}}">&nbsp;&nbsp;{{country.country_name}}</nb-option>

          </nb-select>
        </nb-form-field>
        <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="filterByEmployee($event)"
                     class="w-75 mt-3 mx-2" nbTooltip="Click here to filter prospect by employee"
                     nbTooltipStatus="primary" placeholder="&nbsp;  Employee">
            <nb-option (click)="getAllProspects()" value="All">All</nb-option>
            <nb-option
              *ngFor="let optionEmployee of list"
              value="{{optionEmployee.id}}">{{optionEmployee.employeeName}}</nb-option>


          </nb-select>
        </nb-form-field>
        <!-- <nb-form-field >
                  <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
                  <nb-select (selectedChange)="getProspectsByCity($event,'city')" [disabled]="dataCustomers.length === 0"
                             class="w-75 mt-3 mx-2" placeholder="Filter by city">
                    <nb-option (click)="getAllProspects()" value="All">All</nb-option>
                    <nb-option *ngFor="let city of cities"
                               value="{{city.name}}">{{city.name}}</nb-option>

                  </nb-select>
                </nb-form-field>-->

        <div class="col-lg-10 mt-3">
          <label>Prospects Per Page : </label>
          <input [(ngModel)]="pageSize"
                 class="w-100"
                 min="4" nbInput
                 placeholer="Number to get prospects per page" type="number">
        </div>

      </div>
      <table class="table  col-md-9" id="htmlData">
        <thead class="bg-light">
        <tr>
          <th>Name</th>
          <th>title</th>
          <th>Cell Phone</th>
          <th>Address</th>
          <th>City</th>
          <th>Status</th>
          <th>Actions</th>

        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let customer of dataCustomers | filter : search | paginate : {itemsPerPage:pageSize, currentPage: current,totalItems: total}"
          class="col-md-9 col-sm-10">

          <td>
            <div class="d-flex align-items-center">
              <nb-user [name]="customer.name" onlyPicture picture="{{customer.image}}"></nb-user>
              <div class="ms-3">
                <p class=" mb-1">{{customer.name}}</p>
                <p class=" mb-0">{{customer.workEmail}}</p>
              </div>
            </div>
          </td>
          <td><p class="fw-normal mb-1">{{customer.title}}</p></td>
          <td><p class="fw-normal mb-1">{{customer.phone}}</p></td>
          <td><p class="fw-normal mb-1">{{customer.address}}</p></td>
          <td><p class="fw-normal mb-1">{{customer.city}}</p></td>
          <td>
            <nb-tag-list>
              <nb-tag *ngIf="customer.status === 'Prospect' " [text]="customer.status" size="small"
                      status="danger">
              </nb-tag>
            </nb-tag-list>
          </td>

          <td>
            <button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" mat-icon-button nbButton
                    shape="round" size="small"
                    status="basic">
              <nb-icon icon="more-horizontal-outline"></nb-icon>
            </button>
            <mat-menu #menu="matMenu" class="bg-white">

              <button mat-menu-item>
                <a
                  [routerLink]="['/crm/prospect-detail/',customer.id]"
                  fullWidth nbButton>
                  <span class="text-info">
                  <nb-icon icon="eye-outline"></nb-icon></span> Details
                </a>
              </button>
              <button mat-menu-item>
                <a
                  (click)="updateStatus(customer,customer.id)"
                  fullWidth nbButton>
                  <span class="text-success">
                  <nb-icon icon="checkmark-outline"></nb-icon></span> Approve
                </a>
              </button>
              <button mat-menu-item>
                <a [routerLink]="['/crm/edit-prospect/', customer.id]"
                   fullWidth nbButton>
               <span class="text-warning">
                        <nb-icon icon="edit-outline"></nb-icon>
                      </span> Edit
                </a>
              </button>

              <button mat-menu-item>
                <a (click)="onArchiveConfirm(customer,customer.id)" fullWidth nbButton status="basic">
                  <nb-icon icon="archive-outline"></nb-icon>
                  Archive
                </a>
              </button>

            </mat-menu>
          </td>

        </tr>
        </tbody>
      </table>
      <!-- end col -->

    </div>

    <!-- end row -->
    <pagination-controls (pageChange)="current = $event" align="right"></pagination-controls>
    <!-- end row -->
  </div>
  <!-- container -->
</div>
