
<div *ngIf="customer" class="container">
  <div class="row">
    <div class="col-md-10 col-sm-12">
      <h2 class="more-vertical">
        Prospect Details </h2>
    </div>

    <div align="right" class="col-md-2 justify-content-end">
      <a [routerLink]="['/crm/edit-prospect',customer.id]" class="w-25"
         fullWidth nbButton nbTooltip="Click here to edit prospect data" status="warning" nbTooltipStatus="primary" shape="round">
        <nb-icon icon="edit-outline"></nb-icon>
      </a>
      <a [routerLink]="['/crm/prospects']" class="w-25 mx-3"
         fullWidth nbButton nbTooltip="Back to prospects list" nbTooltipStatus="primary"  shape="round" status="basic">
        <nb-icon icon="corner-up-right-outline"></nb-icon>
      </a>

    </div>

  </div>
  <div class="row mt-3">
    <div class="col-md-4 mb-3">
  <nb-card>
        <nb-card-body class="card-body">
          <div class="d-flex flex-column align-items-center text-center">
            <div class="row">

              <img alt="" src="{{customer.image}}" style="width: 150px;height: 150px;border-radius: 100%;">
            </div>

            <div class="mt-3">
              <h4>{{customer.name}}</h4>
              <div class="row">
                <div class="col-md-2">
                </div>
                <div class="col-md-8">
                     <nb-tag-list>
                  <nb-tag [text]="customer.status" status="danger" appearance="filled"></nb-tag>
                </nb-tag-list>
                </div>
              </div>
               <div class="row mt-3">
              <div class="col-md-3">
                 <button nbButton
                        size="small"
                        shape="round"
                        status="danger">
                  <nb-icon icon="pin-outline"></nb-icon>
                </button>
              </div>
              <div class="col-md-7">
                <p class="mt-1 mx-2">  {{customer.address}}{{customer.city}}{{customer.country}} </p>
              </div>
            </div>

            </div>
          </div>
        </nb-card-body>
      </nb-card>


    </div>
    <div class="col-md-8">
      <nb-accordion>
        <nb-accordion-item expanded="true">
          <nb-accordion-item-header>Personal Information</nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Title</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.title}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Full Name</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.name}}
              </div>
            </div>
            <hr>

            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Address</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.address}},{{customer.city}}  {{customer.country }}
              </div>
            </div>
            <hr>

          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
      <br>
      <nb-accordion>
        <nb-accordion-item expanded="true">
          <nb-accordion-item-header>Contact Information</nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Email Address</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.workEmail}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Cell Phone</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.phone }}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Website</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.workWebsite }}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Linkedin</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.linkedinUrl }}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Description</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.description}}<br>
              </div>
            </div>
            <hr>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
      <br>

      <nb-accordion>
        <nb-accordion-item expanded="true">
          <nb-accordion-item-header>Security Information</nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Social Security</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.active}}
                <span *ngIf="customer.active === null">
                      This field is not fill it yet.
                    </span>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Creation </p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.createdAt | date: 'YYYY MMM dd'}} at
                {{customer.createdAt | date: 'HH:MM'}}


              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Last modification </p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.modifiedAt | date: 'YYYY MMM dd'}} at
                {{customer.modifiedAt | date: 'HH:MM'}}


              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Customer Status</p>
              </div>
              <nb-tag-list>
                <nb-tag *ngIf="customer.status === 'Lead' " [text]="customer.status" size="small"
                        status="basic"></nb-tag>
                <nb-tag *ngIf="customer.status === 'Prospect' " [text]="customer.status" size="small"
                        status="danger"></nb-tag>
                <nb-tag *ngIf="customer.status === 'Client' " [text]="customer.status" size="small"
                        status="primary"></nb-tag>
              </nb-tag-list>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Assignee</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer?.assignee?.employeeName}}
                <span *ngIf="customer?.assignee?.employeeName === 0">
                      This employee does not have any kids.
                    </span>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Added by</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.user}}
              </div>
            </div>
            <hr>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
      <br>
      <div *ngIf="customer.typeProspect === 'Business Prospect (B2B)'" >
      <nb-accordion>
        <nb-accordion-item expanded="true">
          <nb-accordion-item-header>Business Information</nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Company Name</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.companyName}}
                <span *ngIf="customer.companyName === null">
                      This field is not fill it yet.
                    </span>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Industry </p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.industry}}
                <span *ngIf="customer.industry === null">
                      This field is not fill it yet.
                    </span>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Company Size</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.companySize}}
                <span *ngIf="customer.companySize === null">
                      This field is not fill it yet.
                    </span>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Department</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.department}}
                <span *ngIf="customer.department === null">
                      This field is not fill it yet.
                    </span>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">BusinessType</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.businessType}}
                <span *ngIf="customer.businessType === null">
                      This field is not fill it yet.
                    </span>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Annual Revenue</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.annualRevenue}}
                <span *ngIf="customer.annualRevenue === null">
                      This field is not fill it yet.
                    </span>
              </div>
            </div>
            <!--<hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Phone of company</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.companyPhone}}
                <span *ngIf="customer.companyPhone === null">
                      This field is not fill it yet.
                    </span>
              </div>
            </div>-->
            <hr>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
      </div>
    </div>
  </div>




</div>
