<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<div class="container">
  <div class="d-flex flex-column flex-md-row mb-3 mb-md-0">
    <div class="col-md-3">
      <h2>Events</h2>
    </div>
    <div class="col-md-5">
      <input [(ngModel)]="search"
             nbInput class="w-100" fieldSize="large" fullWidth
             nbTooltip="Tap here to search an event "
             nbTooltipStatus="primary" placeholder="Search event" type="text">

    </div>
    <div class="btn-toolbar col-md-4" role="toolbar">
      <div class="mr-auto mr-md-2 btn-group" role="group">

        <a (click)="exportAsXLSX()" aria-current="page" class=" mx-1 align-self-center active"
           id="tooltipExport" nbTooltip="Export data to excel file "
           nbTooltipStatus="primary" nbButton
           status="success"> <i class="fas fa-file-excel text-white"></i>
        </a>
        <button [routerLink]="['/crm/add-event']"
                aria-current="page" class=" mx-1 align-self-center active" nbButton status="info"
                id="tooltipAddDeal" nbTooltip="Click here to create a new event"
                nbTooltipStatus="primary"><nb-icon icon="plus-outline"></nb-icon> Add
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!dataEvents || dataEvents.length === 0" align="center">
    <nb-alert class="w-100 mt-3" outline="danger">No Data Found.</nb-alert>
  </div>
  <div *ngIf="(dataEvents | filter : search).length === 0" [hidden]="dataEvents.length === 0"
       class="">
    <nb-alert class="col-lg-12 mt-3" fullWidth outline="danger">The item searched is not found. Please make sure
      while typing your data
    </nb-alert>
  </div>
  <div class="row mt-3">
    <div class="col-md-3 mt-3">

      <nb-form-field>
        <nb-icon icon="funnel-outline" nbPrefix></nb-icon>
        <nb-select (selectedChange)="getEventsByStatus($event)"
                   class="mx-2 w-75" nbTooltip="Click here to filter by status"
                   nbTooltipStatus="primary"
                   placeholder="&nbsp; Status"
                   selected="0">
          <nb-option (click)="getAllEvent()" value="All">All</nb-option>

          <nb-option value="Scheduled">Scheduled</nb-option>
          <nb-option value="Ongoing">Ongoing</nb-option>
          <nb-option value="Completed">Completed</nb-option>
          <nb-option value="Postponed">Postponed</nb-option>
          <nb-option value="Cancelled">Cancelled</nb-option>
        </nb-select>
      </nb-form-field>

      <nb-form-field>
        <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
        <nb-select (selectedChange)="getEventsByType($event)"
          class="mt-3 mx-2 w-75" nbTooltip="Click here to filter by type"
          nbTooltipStatus="primary"
          placeholder="&nbsp; Types"
          selected="0">
          <nb-option (click)="getAllEvent()" value="All">All</nb-option>

          <nb-option value="Seminar">Seminar</nb-option>
          <nb-option value="workshop">workshop</nb-option>
          <nb-option value="conference">conference</nb-option>
          <nb-option value="Celebration">Celebration</nb-option>
          <nb-option value="Other">Other</nb-option>
        </nb-select>
      </nb-form-field>

      <nb-form-field>
        <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
        <nb-select (selectedChange)="getEventsByAddress($event,'country')"
                   class="mt-3 mx-2 w-75" nbTooltip="Tap to filter by country"
                   nbTooltipStatus="primary"
                   placeholder="&nbsp;  Country">
          <nb-option (click)="getAllEvent()" value="All">All</nb-option>
          <nb-option *ngFor="let country of countries"
                     value="{{country.country_name}}">{{country.country_name}}</nb-option>

        </nb-select>
      </nb-form-field>

      <nb-form-field>
        <nb-icon class="mt-3" icon="flip-outline" nbPrefix></nb-icon>
        <nb-select class="mx-2 mt-3 w-75" nbTooltip="Tap to sort by title"
                   nbTooltipStatus="primary" placeholder="&nbsp; Title" selected="0">
          <nb-option (click)="getAllEvent()" value="All">All</nb-option>
          <nb-option (click)="getEventsTitleAsc()" value="1">Names (A-Z)</nb-option>
          <nb-option (click)="getEventsTitleDesc()" value="2">Names (Z-A)</nb-option>
        </nb-select>
      </nb-form-field>


      <nb-form-field>
        <nb-icon class="mt-3" icon="flip-outline" nbPrefix></nb-icon>
        <nb-select
                   class=" mx-2 mt-3 w-75" nbTooltip="Tap to sort by date creation"
                   nbTooltipStatus="primary" placeholder=" &nbsp; Date creation"
                   selected="0">
          <nb-option (click)="getAllEvent()" value="All">All</nb-option>
          <nb-option (click)="getEventsCreatedDesc()" value="1">Recently added</nb-option>
          <nb-option (click)="getEventsCreatedAsc()" value="2">Old</nb-option>
        </nb-select>
      </nb-form-field>
      <div class="col-lg-10 mt-3">
        <label>Events Per Page : </label>
        <input [(ngModel)]="pageSize" [disabled]="dataEvents.length === 0"
               min="4" nbInput
               nbTooltip="Tap a number to get Events per page"
               nbTooltipStatus="primary"
               placeholer="Number to get events per page"
               type="number">
      </div>
    </div>
    <div class="row col-md-9 ">
      <nb-card *ngFor="let event of dataEvents | filter : search | paginate : {itemsPerPage: pageSize, currentPage: current, totalItems: dataEvents.length}" class="col-lg-6 form-group">
        <div *ngIf="event.statusEvent === 'Scheduled'">
          <nb-badge position="top right" status="success" text={{event.statusEvent}}></nb-badge>
        </div>
        <div *ngIf="event.statusEvent === 'Ongoing'">
          <nb-badge position="top right" status="info" text={{event.statusEvent}}></nb-badge>
        </div>
        <div *ngIf="event.statusEvent === 'Completed'">
          <nb-badge position="top right" status="basic" text={{event.statusEvent}}></nb-badge>
        </div>
        <div *ngIf="event.statusEvent === 'Postponed'">
          <nb-badge position="top right" status="warning" text={{event.statusEvent}}></nb-badge>
        </div>
        <div *ngIf="event.statusEvent === 'Cancelled'">
          <nb-badge position="top right" status="danger" text={{event.statusEvent}}></nb-badge>
        </div>

        <!-- Image de l'événement -->
        <div class="w-100" style="margin-top: 30px;">
          <img src="{{event.image}}" alt="Event Image" style="width: 600px; height: 200px;" class="img-fluid">
        </div>

        <div class="row mt-4">
          <div class="row">
            <div class="col-md-7 mx-2">
              <nb-user [name]="event.user.username" [picture]="event.user.userProfileImage" size="medium"></nb-user>
            </div>
            <div align="right" class="col-md-3">
              <button [matMenuTriggerFor]="menu" aria-expanded="false" aria-haspopup="true"
                      aria-label="Example icon-button with a menu" class="float-right"
                      mat-icon-button nbButton
                      nbTooltip="Click here to see advanced menu"
                      nbTooltipStatus="primary"
                      shape="round"
                      size="small"
                      status="basic">
                <nb-icon icon="more-horizontal-outline"></nb-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <a [routerLink]="['/crm/edit-event', event.id]" fullWidth nbButton>
              <span class="text-warning">
                <nb-icon icon="edit-outline"></nb-icon>
              </span> Edit
                  </a>
                </button>
                <button mat-menu-item>
                  <a [routerLink]="['/crm/event-details', event.id]" fullWidth nbButton>
                    <span class="text-info"><nb-icon icon="eye-outline"></nb-icon></span> Details
                  </a>
                </button>
                <button mat-menu-item>
                  <a fullWidth nbButton (click)="onArchiveConfirm(event,event.id)" status="basic">
                    <nb-icon icon="archive-outline"></nb-icon>
                    Archive
                  </a>
                </button>
              </mat-menu>
            </div>
            <div class="col-md-12 pt-4 mx-3">
              <p>
                <nb-icon class="text-primary" icon="file-outline" nbTooltip="Event title" style="margin-right: 5px;"></nb-icon>
                <b>{{event.title}}</b>
              </p>
              <p>
                <nb-icon class="text-primary" icon="clock-outline" nbTooltip="Event time" style="margin-right: 5px;"></nb-icon>
                <b>{{event.timeOfEvent}}</b>
                  <b>{{event.dateOfEvent | date : ' - EEEE, d MMMM yyyy'}}</b>
              </p>
              <p>
                <nb-icon class="text-primary" icon="pin-outline" nbTooltip="Event location" style="margin-right: 5px;"></nb-icon>
                <b>{{event.address}}, {{event.country}}</b>
              </p>
              <p>
                <nb-icon class="text-primary" icon="info-outline" nbTooltip="Event type" style="margin-right: 5px;"></nb-icon>
                <b>{{ event.type === 'Other' ? event.otherType : event.type }}</b>
              </p>
            </div>
          </div>
        </div>
      </nb-card>

    </div>
  </div>

      <pagination-controls (pageChange)="current = $event" align="right" ></pagination-controls>

</div>

