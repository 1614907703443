<nb-card>

  <nb-card-body>
    <form [formGroup]="crmGroup">
      <nb-card-header>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <h5 class="more-vertical">
              Add Sales Tracking Deal </h5>
          </div>
          <div class="col-md-4">

          </div>
          <div align="right" class="col-md-2 justify-content-end">
            <a [routerLink]="['/crm/crm-team/tracking-deals']" class="w-25 mx-3"
               fullWidth nbButton nbTooltip="Back to Sales Tracking Deals list" nbTooltipStatus="primary"
               shape="round" status="basic">
              <nb-icon icon="corner-up-right-outline"></nb-icon>
            </a>
          </div>
        </div>
      </nb-card-header>
      <nb-stepper #stepper>
        <nb-step [stepControl]="crmGroup" label="Personal">
          <label class="small">Company Name</label>
          <div class="row">
            <div class="col-lg-11">
            <nb-select (selectedChange)="onMenuItemSelectedNomEntreprise($event)"
                       [(ngModel)]="selectedNomEntreprise"
                       size="large" class="w-100"
                       formControlName="nomEntreprise"
                       fullWidth
                       id="nomEntreprise" name="nomEntreprise"
                       placeholder="Company Name">

              <nb-option

                *ngFor="let item of list" [value]="item">
                <nb-user size="medium" [picture]="item.image" [name]="item.name"></nb-user>
              </nb-option>

            </nb-select>
            </div>
            <div class="col-md-1 mt-3">
              <nb-icon icon="info-outline"
                       nbTooltip="Only caracters can be accepted"
                       nbTooltipStatus="primary"></nb-icon>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-6">
              <label class="small">Prospect</label>
              <div class="row">
                <div class="col-md-10">
                  <nb-select (selectedChange)="onMenuItemSelectedProspect($event)"
                             [(ngModel)]="selectedProspect"
                             size="large" class="w-100"
                             formControlName="prospect"
                             fullWidth
                             id="prospect" name="prospect"
                             placeholder="Prospect">

                    <nb-option

                      *ngFor="let item of listP"
                      [value]="item">
                      <nb-user size="medium" [picture]="item.image" [name]="item.name"></nb-user>

                    </nb-option>

                  </nb-select>
                </div>
                <div class="col-md-1 mt-3">
                  <nb-icon icon="info-outline" nbTooltip="Only caracters can be accepted"
                           nbTooltipStatus="primary"></nb-icon>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label class="small">Email Contact</label>
              <div class="row">
                <div class="col-md-10">
                  <input [(ngModel)]="crmTrackingDeals.emailContact" fieldSize="large" formControlName="emailContact" fullWidth
                         id="emailContact"
                         name="emailContact" nbInput placeholder="Email Contact"
                         type="text">
                </div>
                <div class="col-md-1 mt-3">
                  <nb-icon icon="info-outline" nbTooltip="Only caracters can be accepted"
                           nbTooltipStatus="primary"></nb-icon>
                </div>

              </div>
            </div>

          </div>
          <div class="row mt-1">
            <div class="col-md-6">
              <label class="small">Market value</label>
              <div class="row">
                <div class="col-md-10">
                  <input [(ngModel)]="crmTrackingDeals.valeur" fieldSize="large" formControlName="valeur" fullWidth
                         id="marketValue"
                         name="marketValue" nbInput placeholder="Market value"
                         type="text">
                  <div >
                    <small class="text-danger" *ngIf="crmGroup.get('valeur').errors?.min">Market value must be a positive number.</small>
                    <small class="text-danger" *ngIf="crmGroup.get('valeur').errors?.pattern">Invalid format. Only numeric values are allowed.</small>
                  </div>
                </div>
                <div class="col-md-1 mt-3">
                  <nb-icon icon="info-outline" nbTooltip="Only numbers can be accepted"
                           nbTooltipStatus="primary"></nb-icon>
                </div>

              </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                  <div class="col-md-10">
                    <label class="mt-2 small">Stage</label> <br>

                    <nb-select (selectedChange)="onMenuItemSelectedStage($event)"
                               [(ngModel)]="selectedStage"
                               size="large" class="w-100"
                               formControlName="stage"
                               fullWidth
                               id="stage" name="stage"
                               placeholder="Stage">

                      <nb-option

                        *ngFor="let item of listStage"
                        [value]="item">
                        {{ item.stage }} : {{ item.probabilite }}
                      </nb-option>
                    </nb-select>
                 </div>

                </div>

                <div class="row mt-3">
                  <div class="col-md-8"></div>
                  <button (click)="onCrmTrackingDealsFormSubmit()" class="col-md-2" nbButton nbStepperNext
                          status="info" type="submit">next
                  </button>
                </div>

            </div>
          </div>
        </nb-step>
        <nb-step [stepControl]="datesForm" label="Dates">
          <form class="datesForm" formGroupName="datesForm">
            <div class="row">
              <div class="col-lg-12">
                <label class="mt-2 small">Date du premier contact</label> <br>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <input [(ngModel)]="crmTrackingDeals.dateP" [nbDatepicker]="datePickerP"
                       fieldSize="large" formControlName="dateP" fullWidth id="dateP"
                       name="dateP" nbInput
                       placeholder="Pick Date">
                <nb-datepicker #datePickerP></nb-datepicker>
              </div>
              <div class="col-md-1 mt-3">
                <nb-icon icon="info-outline" nbTooltip="Select Date"
                         nbTooltipStatus="primary"></nb-icon>
              </div>
            </div>
            <br>

            <div class="row">
              <div class="col-lg-12">
                <label class="mt-2 small col-lg-12">Date de clôture prévue</label>
              </div>
            </div>

              <div class="row">
                <div class="col-md-11">
                  <input [(ngModel)]="crmTrackingDeals.dateC" [nbDatepicker]="datePickerDEchange"
                         fieldSize="large" formControlName="dateC" fullWidth id="dateC"
                         name="dateC" nbInput
                         placeholder="Pick Date">
                  <nb-datepicker #datePickerDEchange></nb-datepicker>
                </div>
                <div class="col-md-1 mt-3">
                  <nb-icon icon="info-outline" nbTooltip="Select Date"
                           nbTooltipStatus="primary"></nb-icon>
                </div>
              </div>

            <div class="row mt-3">
                <div class="col-lg-12">
                  <label class="mt-2 small col-lg-12">Date dernier échange</label>
                </div>
            </div>
                <div class="row">
                  <div class="col-md-11">
                    <input [(ngModel)]="crmTrackingDeals.dateDEchange" [nbDatepicker]="dateTimePicker"
                           fieldSize="large" formControlName="dateDEchange" fullWidth id="dateDEchange"
                           name="dateDEchange" nbInput
                           placeholder="Pick Date">
                    <nb-datepicker #dateTimePicker></nb-datepicker>
                  </div>
                  <div class="col-md-1 mt-3">
                    <nb-icon icon="info-outline" nbTooltip="Select Date"
                             nbTooltipStatus="primary"></nb-icon>
                  </div>

            </div>
            <br>
            <div class="row mt-3">
              <button class="col-md-2" nbButton nbStepperPrevious>prev</button>
              <div class="col-md-8"></div>
              <button (click)="onDatesFormSubmit()" class="col-md-2" nbButton nbStepperNext
                      status="info" type="submit">next
              </button>
            </div>
          </form>
        </nb-step>

        <nb-step [stepControl]="teamGroup" label="Team ">
          <form class="teamGroup" formGroupName="teamGroup">
          <label class="small">Recette </label>
          <div class="row">
            <div class="col-md-11">
              <input [(ngModel)]="crmTrackingDeals.recetteR" fieldSize="large" formControlName="recetteR"
                     fullWidth
                     id="recetteR" name="recetteR" nbInput placeholder="Recette"
                     type="text">
              <!-- Display validation message if recette is invalid -->
              <div >
                <small class="text-danger" *ngIf="crmGroup.get('teamGroup').get('recetteR').errors?.min">Recette must be a positive number.</small>
                <small class="text-danger" *ngIf="crmGroup.get('teamGroup').get('recetteR').errors?.pattern">Invalid format. Only numeric values are allowed.</small>
              </div>
            </div>
            <div class="col-md-1 mt-3">
              <nb-icon icon="info-outline"
                       nbTooltip="Only numbers can be accepted"
                       nbTooltipStatus="primary"></nb-icon>
            </div>
          </div>

            <div class="row">
              <div class="col-lg-11">
                <label class="mt-2 small">Team Member</label> <br>

                <nb-select (selectedChange)="onMenuItemSelectedTeamMember($event)"
                           [(ngModel)]="selectedTeamMember"
                           size="large" class="w-100"
                           formControlName="teamMember"
                           fullWidth
                           id="teamMember" name="teamMember"
                           placeholder="Team Member">

                  <nb-option

                    *ngFor="let item of listTeamMember"
                    [value]="item"
                  > {{ item.representantCommercial }}
                  </nb-option>

                </nb-select>


              </div>
              <div class="col-md-1 mt-3">
                <nb-icon icon="info-outline" nbTooltip="Select Team Member"
                         nbTooltipStatus="primary"></nb-icon>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-11">
                <label class="mt-2 small">Next Step</label> <br>

                <nb-select (selectedChange)="onMenuItemSelectedNextStep($event)"
                           [(ngModel)]="selectedNextStep"
                           size="large" class="w-100"
                           formControlName="etapeSuivante"
                           fullWidth
                           id="etapeSuivante" name="etapeSuivante"
                           placeholder="Next Step">

                  <nb-option

                    *ngFor="let item of listNextStep"
                    [value]="item">
                    {{ item.etape }}
                  </nb-option>

                </nb-select>


              </div>
              <div class="col-md-1 mt-3">
                <nb-icon icon="info-outline" nbTooltip="Select Next Step"
                         nbTooltipStatus="primary"></nb-icon>
              </div>
            </div>


            <div class="row mt-3">
              <button class="col-md-2" nbButton nbStepperPrevious>prev</button>
              <div class="col-md-8"></div>
              <button (click)="addCrmTeamTrackingDealsFunction()" class="col-md-2" nbButton nbStepperNext
                      status="info" type="submit">Submit
              </button>
            </div>
          </form>
        </nb-step>



      </nb-stepper>
    </form>
  </nb-card-body>
</nb-card>
