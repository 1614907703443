import { AfterViewInit, Component, Input, OnChanges, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';

import { ProfitChart } from '../../../../../../@core/data/profit-chart';
import { LayoutService } from '../../../../../../@core/utils/layout.service';

@Component({
  selector: 'ngx-profit-chart',
  styleUrls: ['./charts-common.component.scss'],
  template: `
    <div #profitChartElement style="width: 100%; height: 400px;"></div>
  `,
})
export class ProfitChartComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input() profitChartData: {
    categories: string[];
    recettePrevData: number[];
    recetteGainData: number[];
    objectifData: number[];
  };

  @ViewChild('profitChartElement', { static: false }) profitChartElement: ElementRef; // Use ViewChild for chart element

  private alive = true;
  echartsInstance: any;
  options: any = {};

  constructor(private theme: NbThemeService,
              private layoutService: LayoutService) {
    this.layoutService.onSafeChangeLayoutSize()
      .pipe(
        takeWhile(() => this.alive),
      )
      .subscribe(() => this.resizeChart());
  }

  ngOnChanges(): void {
    if (this.echartsInstance) {
      this.updateChartOptions();
    }
  }

  ngAfterViewInit(): void {
    this.initChart();  // Initialize chart after view is initialized
    this.theme.getJsTheme().pipe(takeWhile(() => this.alive)).subscribe((config) => {
      const themeVariables: any = config.variables;
      this.updateChartOptions(themeVariables);
    });
  }

  initChart() {
    if (this.profitChartElement && this.profitChartElement.nativeElement) {
      this.echartsInstance = echarts.init(this.profitChartElement.nativeElement);
      this.updateChartOptions();  // Update the chart after initialization
    }
  }

  updateChartOptions(themeVariables = null) {
    if (this.profitChartData) {
      const themeName = this.theme.currentTheme;  // Get the current theme
      const isDarkMode = themeName === 'dark' || themeName === 'cosmic';  // Check for dark or cosmic themes
      const textColor = isDarkMode ? '#fff' : '#000';  // White for dark themes, black for light themes
      const axisLineColor = isDarkMode ? '#ccc' : '#333';  // Light colors for dark themes, darker for light themes
      const splitLineColor = isDarkMode ? '#444' : '#eee';  // Adjust split line color based on theme

      const options = {
        legend: {
          data: ['Recette Prévue', 'Recette de Gain', 'Objectif'],
          textStyle: { color: textColor },
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          data: this.profitChartData.categories,
          axisLine: { lineStyle: { color: axisLineColor } },
          axisLabel: { color: textColor },
        },
        yAxis: {
          type: 'value',
          axisLine: { lineStyle: { color: axisLineColor } },
          splitLine: { lineStyle: { color: splitLineColor } },
          axisLabel: { color: textColor },
        },
        series: [
          {
            name: 'Recette Prévue',
            type: 'bar',
            data: this.profitChartData.recettePrevData,
            itemStyle: { color: '#42A5F5' },  // Customize series colors if needed
          },
          {
            name: 'Recette de Gain',
            type: 'bar',
            data: this.profitChartData.recetteGainData,
            itemStyle: { color: '#66BB6A' },
          },
          {
            name: 'Objectif',
            type: 'bar',
            data: this.profitChartData.objectifData,
            itemStyle: { color: '#FFA726' },
          },
        ],
      };

      this.echartsInstance.setOption(options);  // Set chart options
    }
  }

  resizeChart() {
    if (this.echartsInstance) {
      this.echartsInstance.resize();
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
