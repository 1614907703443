import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {Router} from '@angular/router';
import {Auth} from '../../model/Auth';
import {AuthService} from '../../service/auth.service';
import {TokenStorageService} from '../../service/token/token.service';
import {NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService} from '@nebular/theme';

// import {ReCaptcha2Component} from 'ngx-captcha';
import {environment} from '../../../../../environments/environment';
import { NbAuthOAuth2Token, NbAuthResult, NbAuthService, NbAuthToken } from '@nebular/auth';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginform: FormGroup;
  rememberMe: boolean = false;
  index = 1;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  userRole;
  loginData;
  positions = NbGlobalPhysicalPosition;
  btnDis = false;
  captchaResponse: string = '';
  showPassword = false;
  recaptchaSecret: string = environment.captchaKey;
  token: NbAuthOAuth2Token;
  private destroy$ = new Subject<void>();

  getRole = localStorage.getItem('userrole');
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private toastrService: NbToastrService,
    private authServices: NbAuthService,
  ) {

    this.authServices
      .onTokenChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe((token: NbAuthToken) => {
        this.token = null;
        if (token && token.isValid()) {
          this.token = token as NbAuthOAuth2Token;
        }
      });

  }

  /*togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }*/
  showToast(message, position, status) {
    this.toastrService.show(status || 'Success', message, {position, status});
  }

  ngOnInit(): void {

    this.initForm();

    const rememberMe = localStorage.getItem('rememberMe');

    if (rememberMe) {

      this.rememberMe = JSON.parse(rememberMe);

      this.loginform.patchValue({ rememberMe: this.rememberMe });

    }

    if (this.rememberMe) {

      const email = localStorage.getItem('email');

      const password = localStorage.getItem('password');

      if (email && password) {

        this.loginform.patchValue({ email, password });

        this.login();

      }

    }

  }


  initForm() {

    this.loginform = this.fb.group({

      email: new FormControl('', [

        Validators.required,

        Validators.email,

        Validators.pattern(

          /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}$/u ,

        ),

      ]),

      password: new FormControl('', [Validators.required]),

      rememberMe: new FormControl(false),

    });

  }

  redirectToGmail() {
    window.location.href = 'https://www.gmail.com/';
  }

  get email() {
    return this.loginform.get('email');
  }

  get password() {
    return this.loginform.get('password');
  }

  get recaptcha() {
    return this.loginform.get('recaptcha');
  }

  login() {
    this.btnDis = true;
    const { email, password, rememberMe } = this.loginform.value;

    // Store credentials if "Remember me" is checked
    if (rememberMe) {
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);
      localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
    } else {
      // Clear stored credentials if "Remember me" is unchecked
      localStorage.removeItem('email');
      localStorage.removeItem('password');
      localStorage.removeItem('rememberMe');
    }
    this.loginData = {
      email: this.loginform.get('email').value,
      password: this.loginform.get('password').value,
    };
    this.authService.login(this.loginData).subscribe({
        next: (user) => {
          this.tokenStorageService.saveToken(user.token);
          this.tokenStorageService.saveUser({
            email: user.email,
            username: user.username,
            company: user.company,
            matriculate: user.matriculate,
            role: user.roles.map((item) => item),
            id: user.id,
          });
          this.userRole = user.roles.map((item) => item.name).join(',');

          console.log('user role component ::::::: ' + this.userRole);

          console.log('user role component ::::::: ' + this.getRole);


          if (this.userRole === 'ROLE_CRM_MANAGER') {

            window.location.href = '/crm/prospects';

            // this.router.navigate(['/hr/organigram/view']).then(() => {

          }

          if (this.userRole === 'ROLE_HR_MANAGER') {
            this.showToast('Logged In', this.positions.TOP_RIGHT, 'success');

            window.location.href = '/hr/organigram/view';
            // this.router.navigate(['/hr/organigram/view']).then(() => {
          }

          /*if (this.userRole === 'ROLE_PROJECT_MANAGER') {
            this.showToast('Logged In', this.positions.TOP_RIGHT, 'success');
            this.router.navigate(['/projectManagement/process']).then(() => {
              window.location.reload();
            });
          }

          if (this.userRole === 'ROLE_CRM_MANAGER') {
            this.showToast('Logged In', this.positions.TOP_RIGHT, 'success');
            this.router.navigate(['/crm/prospects']).then(() => {
              window.location.reload();
            });
          }

          if (this.userRole === 'ROLE_CMKG_MANAGER') {
            this.showToast('Logged In', this.positions.TOP_RIGHT, 'success');
            this.router.navigate(['/communicationMarketing/personaStat']).then(() => {
              window.location.reload();
            });
          }

          if (this.userRole === 'ROLE_FIN_MANAGER') {
            this.showToast('Logged In', this.positions.TOP_RIGHT, 'success');
            this.router.navigate(['/finance/financeInsights']).then(() => {
              window.location.reload();
            });
          }

          if (this.userRole === 'ROLE_ACC_MANAGER') {
            this.showToast('Logged In', this.positions.TOP_RIGHT, 'success');
            this.router.navigate(['/accounting/insights']).then(() => {
              window.location.reload();
            });
          }

          if (this.userRole === 'ROLE_PIM_MANAGER') {
            this.showToast('Logged In', this.positions.TOP_RIGHT, 'success');
            this.router.navigate(['/pim/dashboard']).then(() => {
              window.location.reload();
            });
          }

          if (this.userRole === 'ROLE_RL_MANAGER') {
            this.showToast('Logged In', this.positions.TOP_RIGHT, 'success');
            this.router.navigate(['/litige/statistique']).then(() => {
              window.location.reload();
            });
          }

          if (this.userRole === 'ROLE_ADMIN') {
            this.showToast('Logged In', this.positions.TOP_RIGHT, 'success');
            this.router.navigate(['/administration']).then(() => {
              window.location.reload();
            });
          }

          if (this.userRole === 'ROLE_SUPER_ADMIN') {
            this.showToast('Logged In', this.positions.TOP_RIGHT, 'success');
            this.router.navigate(['/administration/dashboard']).then(() => {
              window.location.reload();
            });
          }*/
        },
        error: (error) => {
          this.btnDis = false;
          if (error.status === 404) {
            this.showToast('This user account does not exist, please enter an existing account' ,  this.positions.TOP_RIGHT, 'warning');
          } else  if (error.status === 401) {
            this.showToast('Invalid Credentials, please verify your password', this.positions.TOP_RIGHT, 'warning');

          } else if (error.status === 303) {

            if (error.error.tempLocked) {
              this.showToast('Too many login attempts, your account is temporary locked. Please answer the 3 security questions to unlock',
                this.positions.TOP_RIGHT, 'danger');
              this.router.navigate(['/auth/unlocking', this.email.value]);

            } else if (!error.error.accountEnabled && error.error.nonLocked) {
              this.showToast('your account is disabled, please contact your administrator',
                this.positions.TOP_RIGHT, 'danger');

            } else if (!error.error.nonLocked) {
              this.showToast('your account is blocked, please contact Manajero administrator',
                this.positions.TOP_RIGHT, 'danger');
            }

          }
        },
      },
    );

  }

  onCaptchaResolved(response: string) {
    this.captchaResponse = response;
  }
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
    console.log(this.showPassword);
  }

  loginwithGmail() {
    this.authServices
      .authenticate('google')
      .pipe(takeUntil(this.destroy$))
      .subscribe((authResult: NbAuthResult) => {});

  }
  logout() {
    this.authService
      .logout('google')
      .pipe(takeUntil(this.destroy$))
      .subscribe((authResult: NbAuthResult) => {});
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
