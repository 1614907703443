<div class="container">
  <div class="d-flex flex-column flex-md-row mb-3 mb-md-0">
    <div class="col-md-3">
      <h2>Claims Archived</h2>
    </div>
    <div class="col-md-5">
      <input [(ngModel)]="search"
             class="w-100" nbInput
             nbTooltip="Tap here to search a claim " fieldSize="large"
             nbTooltipStatus="primary" placeholder="Search claim" type="text">

    </div>

    <div class="btn-toolbar col-md-4" role="toolbar">

      <div class="mr-auto mr-md-2 btn-group" role="group">

        <a (click)="exportAsXLSX()" aria-current="page" class=" mx-1 align-self-center active"
           id="tooltipExport" nbButton
           nbTooltip="Export data to excel file " nbTooltipStatus="primary"
          status="success"> <i class="fas fa-file-excel text-white"></i>
        </a>

      </div>
    </div>
  </div>

  <div *ngIf="!dataClaims || dataClaims.length === 0" align="center">
    <nb-alert class="w-100 mt-3" outline="danger">No Data Found.</nb-alert>
  </div>
  <div *ngIf="(dataClaims | filter : search).length === 0" [hidden]="dataClaims.length === 0"
       class="">
    <nb-alert class="col-lg-12 mt-3" fullWidth outline="danger">The item searched is not found. Please make sure
      while typing your data
    </nb-alert>
  </div>


  <div class="row mt-3">
    <div class="row col-md-12 mt-3">
              <table class="table mb-0 " id="htmlData">
            <thead class="bg-light">
            <tr>
              <th>Title</th>
              <th> Type</th>
              <th>Motif</th>
              <th>Status</th>
              <th>Evaluation claim</th>
              <th>Urgency type</th>
              <th>Actions</th>

            </tr>
            </thead>
            <tbody
              *ngFor="let claim of dataClaims | filter : search | paginate : {itemsPerPage:pageSize, currentPage: current,totalItems: total}">
            <tr class="col-md-9 col-sm-10">
              <td>
                <p class=" mb-1">{{claim.claimTitle}}</p>
              </td>
              <td><p class=" mb-0">{{claim.claimType}}</p>

              </td>
              <td><p class="fw-normal mb-1">{{claim.claimMotif}}</p></td>
              <td><p class="fw-normal mb-1">{{claim.status}}</p></td>
              <td><p class="fw-normal mb-1">{{claim.evaluationClaim }}</p></td>
              <td><p class="fw-normal mb-1" *ngIf="claim.urgencyType === 'High'">
                  <nb-progress-bar [value]="70" status="success">{{claim.urgencyType}}</nb-progress-bar>
              </p>

                <p class="fw-normal mb-1" *ngIf="claim.urgencyType === 'Highest'">
                  <nb-progress-bar [value]="100" status="danger">{{claim.urgencyType}}</nb-progress-bar>
                </p>
                 <p class="fw-normal mb-1" *ngIf="claim.urgencyType === 'Low'">
                  <nb-progress-bar [value]="25" status="basic">{{claim.urgencyType}}</nb-progress-bar>
                </p>

              </td>


              <td>
                  <button [matMenuTriggerFor]="menu"  class="file-close" aria-expanded="false" aria-haspopup="true"
                            aria-label="Example icon-button with a menu" mat-icon-button
                            nbButton nbTooltip="Click here to see advanced menu"
                            nbTooltipStatus="primary"
                            shape="round"
                            size="small"
                            status="basic">
                      <nb-icon icon="more-horizontal-outline"></nb-icon>
                    </button>
                    <mat-menu #menu="matMenu">



               <button mat-menu-item >
                        <a  fullWidth nbButton status="basic" (click)="onRestoreConfirm(claim,claim.id)">
                          <nb-icon icon="refresh-outline"></nb-icon>Restore
                        </a>
                      </button>
                    </mat-menu>
              </td>
            </tr>
            </tbody>
          </table>

    </div>

  </div>

        <pagination-controls (pageChange)="current = $event" align="right"></pagination-controls>

</div>
