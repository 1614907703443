<div class="container">
  <div class="d-flex flex-column flex-md-row mb-3 mb-md-0">
    <div class="col-md-3">
      <h2>Claims</h2>
    </div>
    <div class="col-md-5">
      <input [(ngModel)]="search"
             class="w-100" nbInput
             nbTooltip="Tap here to search a claim " fieldSize="large"
             nbTooltipStatus="primary" placeholder="Search claim" type="text">

    </div>

    <div class="btn-toolbar col-md-4" role="toolbar">

      <div class="mr-auto mr-md-2 btn-group" role="group">
       <a [routerLink]="['/crm/grid-claims']" aria-current="page"
           class=" mx-1 align-self-center active text-white"
           id="tooltipShowSimpleGrid" nbButton
           nbTooltip="Visualize data on grid " nbTooltipStatus="primary" status="primary">
          <nb-icon icon="grid-outline"></nb-icon>
        </a>
       <a [routerLink]="['/crm/claims']" aria-current="page"
           class=" mx-1 align-self-center active text-white"
           id="tooltipShowGrid" nbButton
           nbTooltip="Visualize data on keypad " nbTooltipStatus="primary" status="warning">
          <nb-icon icon="keypad-outline"></nb-icon>
        </a>
        <a [routerLink]="['/crm/list-claims']" class="mx-1 align-self-center " id="tooltipShowList"
           nbButton
           nbTooltip="Visualize data on list "
           nbTooltipStatus="primary"
           status="basic">
          <nb-icon icon="list-outline">
          </nb-icon>
        </a>


        <a (click)="exportAsXLSX()" aria-current="page" class=" mx-1 align-self-center active"
           id="tooltipExport" nbButton
           nbTooltip="Export data to excel file " nbTooltipStatus="primary"
          status="success"> <i class="fas fa-file-excel text-white"></i>
        </a>
        <button [routerLink]="['/crm/add-claim']"
                aria-current="page" class=" mx-1 align-self-center active" id="tooltipAddDeal" nbButton
                nbTooltip="Click here to create a new visit" nbTooltipStatus="primary"
                status="primary"><nb-icon icon="plus-outline"></nb-icon>Add
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!dataClaims || dataClaims.length === 0" align="center">
    <nb-alert class="w-100 mt-3" outline="danger">No Data Found.</nb-alert>
  </div>
  <div *ngIf="(dataClaims | filter : search).length === 0" [hidden]="dataClaims.length === 0"
       class="">
    <nb-alert class="col-lg-12 mt-3" fullWidth outline="danger">The item searched is not found. Please make sure
      while typing your data
    </nb-alert>
  </div>


  <div class="row mt-3">
    <div class="col-md-3 mt-3">

      <nb-form-field>
            <nb-icon icon="funnel-outline" nbPrefix></nb-icon>
           <nb-select (selectedChange)="filterByStatus($event)"
                      class="mx-2 w-75" nbTooltip="Click here to filter by status"
                      nbTooltipStatus="primary"
                      placeholder="&nbsp;  Status"
                      selected="0">
             <nb-option (click)="getAllClaims()" value="All">All</nb-option>

             <nb-option value="Pending">Pending</nb-option>
             <nb-option value="In Progress">In Progress</nb-option>
             <nb-option value="Resolved">Resolved</nb-option>
           </nb-select>
         </nb-form-field>


           <nb-form-field>
             <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
             <nb-select (selectedChange)="filterByEmployee($event)"
                        class="mt-3 mx-2 w-75" placeholder="&nbsp; Employee"
             nbTooltip="Click here to filter claim by employee" nbTooltipStatus="primary">
               <nb-option (click)="getAllClaims()" value="All">All</nb-option>
               <nb-option
                   *ngFor="let optionEmployee of list"
                   value="{{optionEmployee.id}}">{{optionEmployee.employeeName}}</nb-option>


             </nb-select>
           </nb-form-field>


               <nb-form-field>
             <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
             <nb-select (selectedChange)="filterByCustomer($event)"
                        class="mt-3 mx-2 w-75" placeholder="&nbsp; Customer"
             nbTooltip="Click here to filter claim by customer" nbTooltipStatus="primary">
               <nb-option (click)="getAllClaims()" value="All">All</nb-option>
                <nb-option
                   *ngFor="let customer of dataCustomers"
                   value="{{customer.id}}">{{customer.name}}</nb-option>
             </nb-select>
           </nb-form-field>
          <nb-form-field>
             <nb-icon icon="flip-outline" class="mt-3 mx-2" nbPrefix></nb-icon>
             <nb-select [disabled]="dataClaims.length === 0"
                        class="mx-2 mt-3 w-75" nbTooltip="Tap to sort by title "
                        nbTooltipStatus="primary" placeholder="&nbsp; Title"
                        selected="0">
               <nb-option (click)="getAllClaims()" value="All">All</nb-option>
               <nb-option (click)="ascTitleClaims()" value="1">Names (A-Z)</nb-option>
               <nb-option (click)="descClaimsTitle()" value="2">Names (Z-A)</nb-option>
             </nb-select>
           </nb-form-field>
             <nb-form-field>
             <nb-icon class="mt-3" icon="flip-outline" nbPrefix></nb-icon>
             <nb-select [disabled]="dataClaims.length === 0"
                        class=" mx-2 w-75 mt-3" nbTooltip="Tap to sort by date creation"
                        nbTooltipStatus="primary" placeholder="&nbsp; Date creation"
                        selected="0">
               <nb-option (click)="getAllClaims()" value="All">All</nb-option>
               <nb-option (click)="descCreatedAtClaims()" value="1">Recently added</nb-option>
               <nb-option (click)="ascCreatedAtClaims()" value="2">Old</nb-option>
             </nb-select>
           </nb-form-field>
      <div class="col-lg-10 mt-3">
        <label>Claims Per Page : </label>
        <input [(ngModel)]="pageSize" [disabled]="dataClaims.length === 0"
              class="w-100"
               min="9" nbInput
               nbTooltip="Tap a number to get Claims per page" nbTooltipStatus="primary"
               placeholer="Number to get Claims per page" type="number">
      </div>
    </div>
    <div class="row col-md-9">
      <nb-card
        *ngFor="let claim of dataClaims| filter : search | paginate : {itemsPerPage:pageSize, currentPage: current,totalItems: dataClaims?.length}"
        class="col-lg-6 form-group">


        <div class="row mt-3">
            <div class="row">
              <div class="col-md-7 mx-2">
                <nb-user [picture]="claim?.customer?.image" [name]="claim?.customer?.name" size="medium">

                </nb-user>
              </div>

               <div class="col-md-3" align="right">
                                   <button [matMenuTriggerFor]="menu"  class="file-close"aria-expanded="false" aria-haspopup="true"
                            aria-label="Example icon-button with a menu" mat-icon-button
                            nbButton nbTooltip="Click here to see advanced menu"
                            nbTooltipStatus="primary"
                            shape="round"
                            size="small"
                            status="basic">
                      <nb-icon icon="more-horizontal-outline"></nb-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item>
                        <a [routerLink]="['/crm/edit-claim/',claim.id]"
                           fullWidth nbButton>
                      <span class="text-warning">
                        <nb-icon icon="edit-outline"></nb-icon>
                      </span> Edit
                        </a>
                      </button>

                      <button mat-menu-item>
                        <a [routerLink]="['/crm/claim-detail/',claim.id]" fullWidth nbButton>
                          <span class="text-info"><nb-icon icon="eye-outline"></nb-icon></span>Details
                        </a>
                      </button>
                         <button mat-menu-item>
                        <a [routerLink]="['/crm/respond-claim/',claim.id]" fullWidth nbButton>
                          <span class="text-info"><nb-icon icon="corner-up-left-outline"></nb-icon></span>Respond
                        </a>
                      </button>


               <button mat-menu-item >
                        <a  fullWidth nbButton status="basic" (click)="onArchiveConfirm(claim,claim.id)">
                          <nb-icon icon="archive-outline"></nb-icon>Archive
                        </a>
                      </button>
                    </mat-menu>
                                </div>
              <div class="col-md-12 pt-4 mx-3">


                <p>
                  <nb-icon class="text-primary" icon="text-outline" nbTooltip="Claim title"></nb-icon>
                  <b>{{claim.claimTitle}}</b>
                </p>
                <p>
                  <nb-icon class="text-primary" icon="alert-circle-outline" nbTooltip="Claim type"></nb-icon>
                  <b>{{claim.claimType}}</b>
                </p>
                <p>
                  <nb-icon class="text-primary" icon="bulb-outline" nbTooltip="Claim motif"></nb-icon>
                  <b>{{claim.claimMotif}}</b>
                </p>
                <p>
                  <nb-icon class="text-primary" icon="activity-outline" nbTooltip="Claim evaluation"></nb-icon>
                  <b>{{claim.status}}</b>
                </p>
                  <p>
                  <nb-icon class="text-primary" icon="star-outline" nbTooltip="Claim evaluation"></nb-icon>
                  <b>{{claim.evaluationClaim}}</b>
                </p>


                   <p class="w-75 mt-3 text-center" *ngIf="claim.urgencyType === 'High'">
                  <nb-progress-bar [value]="70" status="success">{{claim.urgencyType}}</nb-progress-bar>
                </p>

                <p class="w-75 mt-3 text-center" *ngIf="claim.urgencyType === 'Highest'">
                  <nb-progress-bar [value]="100" status="danger">{{claim.urgencyType}}</nb-progress-bar>
                </p>
                 <p class="w-75" *ngIf="claim.urgencyType === 'Low'">
                  <nb-progress-bar [value]="25" status="basic">{{claim.urgencyType}}</nb-progress-bar>
                </p>


              </div>
            </div>


        </div>


      </nb-card>

    </div>

  </div>
        <pagination-controls (pageChange)="current = $event" align="right"></pagination-controls>


</div>
