import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Customer} from '../../../../models/Customer';
import {CustomerService} from '../../../../services/customers/customer.service';
import {CrmStages} from '../../../../models/CrmStages';
import {CrmStagesService} from '../../../../services/crmStages/crm-stages.service';
import {CrmTeamObjective} from '../../../../models/CrmTeamObjective';
import {CrmTeamObjectiveService} from '../../../../services/crmTeamObjective/crm-team-objective.service';
import {CrmEtapes} from '../../../../models/CrmEtapes';
import {CrmEtapesService} from '../../../../services/CrmEtapes/crm-etapes.service';
import {CrmTeamTrackingDealsService} from '../../../../services/crmTeamTrackingDeals/crm-team-tracking-deals.service';
import {CrmTrackingDeals} from '../../../../models/CrmTrackingDeals';
import {ActivatedRoute, Router} from '@angular/router';
import {NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService} from '@nebular/theme';
import {LocalDataSource} from 'ng2-smart-table';
import {forkJoin, Observable} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'ngx-edit-crm-team-tracking-deals',
  templateUrl: './edit-crm-team-tracking-deals.component.html',
  styleUrls: ['./edit-crm-team-tracking-deals.component.scss'],
})
export class EditCrmTeamTrackingDealsComponent implements OnInit {
  crmTrackingDeals: CrmTrackingDeals = new CrmTrackingDeals();
  crmGroup: FormGroup = new FormGroup({});
  datesForm: FormGroup = new FormGroup({});
  teamGroup: FormGroup = new FormGroup({});
  prefixPhoneSupp: String = '';
  source: LocalDataSource = new LocalDataSource();
  selectedCustomer: string = '';
  selectedTeamObjective: string = '';
  list: Customer[] = [];
  listP: Customer[] = [];
  search: string = '';
  current: number = 1;
  total: string | number;
  pageSize: number = 4;
  listStage: CrmStages[] = [];
  listTeamMember: CrmTeamObjective[] = [];
  listNextStep: CrmEtapes[] = [];
  listCrmTeamTrackingDeals: CrmTrackingDeals[] = [];
  totalValeur: string;
  totalRecette: string;
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  selectedFile: File = null;
  fb = '';
  idUri = '';
  downloadURL: Observable<string>;
  selectedStage: CrmStages;
  stageToStore: any;
  selectedNomEntreprise: Customer;
  nomEntrepriseToStore: any;
  selectedTeamMember: CrmTeamObjective;
  teamMemberToStore: any;
  selectedNextStep: CrmEtapes;
  nextStepToStore: any;
  selectedProspect: Customer;
  prospectToStore: any;
  linearMode = true;
  loading: boolean;
  user: any;

  constructor(private changeDetectorRefs: ChangeDetectorRef,
              private es: CustomerService,
              private st: CrmStagesService,
              private to: CrmTeamObjectiveService,
              private et: CrmEtapesService,
              private activatedroute: ActivatedRoute,
              private ct: CrmTeamTrackingDealsService,
              private toastrService: NbToastrService,
              private router: Router) {
    this.crmGroup = new FormGroup({
      nomEntreprise: new FormControl(''),
      prospect: new FormControl(''),
      emailContact: new FormControl(''),
      valeur: new FormControl('', [Validators.min(0), Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$')]), // Ensure positive numbers
      stage: new FormControl(''),

      teamGroup: new FormGroup({
        recette: new FormControl(''),
        recetteR: new FormControl('', [Validators.min(0), Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$')]), // Ensure positive numbers
        teamMember: new FormControl(''),
        etapeSuivante: new FormControl(''),

      }),

      datesForm : new FormGroup({
        dateP: new FormControl(''),
        dateC: new FormControl(''),
        dateDEchange: new FormControl(''),
      }),

    });
  }
  get nomEntreprise() {
    return this.crmGroup.get('nomEntreprise');
  }

  get prospect() {
    return this.crmGroup.get('prospect');
  }

  get emailContact() {
    return this.crmGroup.get('emailContact');
  }

  get valeur() {
    return this.crmGroup.get('valeur');
  }

  get stage() {
    return this.crmGroup.get('stage');
  }

// Getters for teamGroup
  get recette() {
    return this.crmGroup.get('teamGroup').get('recette');
  }


  get recetteR() {
    return this.teamGroup.get('recetteR');
  }

  get dateP() {
    return this.datesForm.get('dateP');
  }

  get dateC() {
    return this.datesForm.get('dateC');
  }

  get teamMember() {
    return this.teamGroup.get('teamMember');
  }

  get dateDEchange() {
    return this.datesForm.get('dateDEchange');
  }

  get etapeSuivante() {
    return this.teamGroup.get('etapeSuivante');
  }

  onCrmTrackingDealsFormSubmit() {
    this.crmGroup.markAsDirty();
  }

  onDatesFormSubmit() {
    this.datesForm.markAsDirty();
  }

  onTeamFormSubmit() {
    this.teamGroup.markAsDirty();
  }
  onMenuItemSelectedNomEntreprise(item: Customer) {
    this.selectedNomEntreprise = item;
  }

  onMenuItemSelectedNextStep(item: CrmEtapes) {
    this.selectedNextStep = item;
  }

  onMenuItemSelectedStage(item: CrmStages) {
    this.selectedStage = item;
  }

  onMenuItemSelectedTeamMember(item: CrmTeamObjective) {
    this.selectedTeamMember = item;
  }

  onMenuItemSelectedProspect(item: Customer) {
    this.selectedProspect = item;
  }


  ngOnInit(): void {

    this.es.findProspects().subscribe(
      (customers: Customer[]) => {
        this.listP = customers;
      }, (err) => {
        console.log(err);
      },
    );

    this.es.getAllClients().subscribe(
      (customers: Customer[]) => {
        this.list = customers;
      }, (err) => {
        console.log(err);
      },
    );

    this.st.findAllCrmStages().subscribe(
      (crmStages: CrmStages[]) => {
        this.listStage = crmStages;
      }, (err) => {
        console.log(err);
      },
    );

    this.to.findAllCrmTeamObjective().subscribe(
      (crmTeamObjectives: CrmTeamObjective[]) => {
        this.listTeamMember = crmTeamObjectives;
      }, (err) => {
        console.log(err);
      },
    );

    this.et.findAllCrmEtapes().subscribe(
      (crmEtapes: CrmEtapes[]) => {
        this.listNextStep = crmEtapes;
      }, (err) => {
        console.log(err);
      },
    );

    this.ct.findAllCrmTrackingDeals().subscribe(
      (response) => {
        if (Array.isArray(response)) {
        } else {
          console.error('Expected an array of data:', response);
        }
      },
      error => {
        console.error('Error loading CRM TrackingDeals:', error);
      },
    );
      // After the current user is loaded, proceed to load event data.
      this.activatedroute.paramMap.subscribe(result => {
        this.idUri = result.get('id');
        if (this.idUri) {
          this.getAllCrmTrackingDeals(this.idUri);
        }
    }, error => {
      console.error('Failed to load user data:', error);
    });

  }

  getAllCrmTrackingDeals(id: string) {
    this.ct.getCrmTrackingDealsByID(id).subscribe(data => {
      this.crmTrackingDeals = data;
      this.initializeForm(data);
      // Now the user data is guaranteed to be loaded before the event is fetched
      // Additional setup or methods to handle event data can be called here if necessary
    }, error => {
      console.error('Failed to load event data:', error);
    });
  }
  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
  initializeForm(data: CrmTrackingDeals): void {
    this.crmGroup = new FormGroup({
      nomEntreprise: new FormControl(data.nomEntreprise || ''),
      prospect: new FormControl(data.prospect || ''),
      emailContact: new FormControl(data.emailContact || ''),
      valeur: new FormControl(data.valeur),
      stage: new FormControl(data.stage || ''),
      teamGroup: new FormGroup({
        recette: new FormControl(data.recette),
        recetteR: new FormControl(data.recetteR),
        teamMember: new FormControl(data.teamMember || ''),
        etapeSuivante: new FormControl(data.etapeSuivante || ''),
      }),
      datesForm: new FormGroup({
        dateP: new FormControl(data.dateP),
        dateC: new FormControl(data.dateC),
        dateDEchange: new FormControl(data.dateDEchange),
      }),
    });  if (data.nomEntreprise) {
      this.selectedNomEntreprise = this.list.find(item => item.id === data.nomEntreprise.id);
    }
    if (data.prospect) {
      this.selectedProspect = this.listP.find(item => item.id === data.prospect.id);
    }
    if (data.etapeSuivante) {
      this.selectedNextStep = this.listNextStep.find(item => item.id === data.etapeSuivante.id);
    }
    if (data.stage) {
      this.selectedStage = this.listStage.find(item => item.id === data.stage.id);
    }
    if (data.teamMember) {
      this.selectedTeamMember = this.listTeamMember.find(item => item.id === data.teamMember.id);
    }


  }

    updateCrmTrackingDeals() {
    if (this.crmGroup.valid) {
      const valeur = parseFloat(this.crmTrackingDeals.valeur);
      const probabilite = parseFloat(this.crmTrackingDeals.stage.probabilite) / 100;

      const recette = (valeur * probabilite).toString(); // Calculate 'recette'

      const updatedDeal: CrmTrackingDeals = {
        id: this.crmTrackingDeals.id,
        nomEntreprise: this.selectedNomEntreprise,
        prospect: this.selectedProspect,
        stage: this.selectedStage,
        teamMember: this.selectedTeamMember,
        etapeSuivante: this.selectedNextStep,
        emailContact: this.crmGroup.get('emailContact').value,
        valeur: this.crmGroup.get('valeur').value,
        dateC: this.crmGroup.get('datesForm').get('dateC').value,
        dateP: this.crmGroup.get('datesForm').get('dateP').value,
        dateDEchange: this.crmGroup.get('datesForm').get('dateDEchange').value,
        recetteR: this.crmGroup.get('teamGroup').get('recetteR').value,
        recette: recette,
      };

      console.log('Sending updated deal:', updatedDeal);

      this.ct.updateCrmTrackingDeals(updatedDeal).subscribe(
        result => {
          this.showToast('success', 'Update!', 'Sales Tracking Deal Updated Successfully!!');
          this.router.navigateByUrl('/crm/crm-team/tracking-deals');
        },
        error => {
          console.error('Error during sales tracking deal update:', error);
          this.showToast('danger', 'Update Failed', error.message || 'Unknown error');
        },
      );
    } else {
      this.showToast('warning', 'Validation Error', 'Please fill all required fields');
      this.crmGroup.markAllAsTouched(); // Highlight all fields that are invalid
    }
  }

  // toast notification alert
  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }
}
