import {Component, OnInit} from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import {CrmTeamObjectiveService} from '../../../services/crmTeamObjective/crm-team-objective.service';
import {CrmTeamObjective} from '../../../models/CrmTeamObjective';
import {NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService} from "@nebular/theme";
@Component({
  selector: 'ngx-crm-team-objectives',
  templateUrl: './crm-team-objectives.component.html',
  styleUrls: ['./crm-team-objectives.component.scss'],
})
export class CrmTeamObjectivesComponent {
  source: LocalDataSource = new LocalDataSource();
  crmTeamObjective: CrmTeamObjective = new CrmTeamObjective();
  index = 1;
  destroyByClick = true;
  toastduration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  settings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      representantCommercial: { title: 'REPRESENTANT COMMERCIAL', type: 'string' },
      objectifGain: {
        title: 'OBJECTIFS DE GAIN POUR 2024 (DINARS)',
        type: 'number',  // Changez ici pour 'number' afin de forcer la saisie numérique
        editor: {
          type: 'input',
          config: {
            validators: {
              required: true,
              pattern: '^[0-9]*$',  // Valider que la saisie est uniquement des chiffres
            },
          },
        },
      },
    },
  };




  constructor(private service: CrmTeamObjectiveService, private toastrService: NbToastrService) {
    // const data = this.service.getData();
    // this.source.load(data);
    this.loadCrmTeamObjectives();
  }

  onCreateConfirm(event): void {
    const newData = event.newData;

    // Valider que "objectifGain" est bien un nombre
    if (!newData.objectifGain || isNaN(newData.objectifGain)) {
      this.showToast('danger', 'Error', 'Please enter a valid number for Objectif de Gain');
      event.confirm.reject();
      return;
    }

    this.service.addCrmTeamObjective(newData).subscribe(
      data => {
        this.loadCrmTeamObjectives();
        event.confirm.resolve();
      },
      error => {
        console.log('Error adding CRM team Objective:', error);
        event.confirm.reject();
      },
    );
  }


  updateTotalAndLoadData(response: any) {
    response = response.filter(item => item.representantCommercial !== 'Total Objectives for the Team');
    // Calculate total gains
    const totalGain = response.reduce((acc, curr) => {
      const gain = parseFloat((curr.objectifGain || '0').replace(/,/g, ''));
      return acc + gain;
    }, 0);

    // Create a total line object
    const totalLine = {
      representantCommercial: 'Total Objectives for the Team',
      objectifGain: totalGain, // Format the total as needed
    };

    // Add this line to the end of the data array
    response.unshift(totalLine);

    // Load the updated array into the data source
    this.source.load(response);
  }


  loadCrmTeamObjectives() {
    this.service.findAllCrmTeamObjective().subscribe(
      (response: any) => {
        if (Array.isArray(response)) {
          this.updateTotalAndLoadData(response);
        } else {
          console.error('Expected an array of data:', response);
        }
      },
      error => {
        console.error('Error loading CRM team Objectives:', error);
      },
    );
  }




  onEditConfirm(event): void {
    const newData = event.newData;

    // Valider que "objectifGain" est bien un nombre
    if (!newData.objectifGain || isNaN(newData.objectifGain)) {
      this.showToast('danger', 'Error', 'Please enter a valid number for Objectif de Gain');
      event.confirm.reject();
      return;
    }

    this.service.updateCrmTeamObjective(newData).subscribe(
      data => {
        event.confirm.resolve(data);
        this.source.update(event.data, data);
      },
      error => {
        console.log('Error updating CRM team Objective:', error);
        event.confirm.reject();
      },
    );
  }
  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.toastduration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }

  onDeleteConfirm(event): void {
      this.service.deleteCrmTeamObjective(event.data).subscribe(data => {
        event.confirm.resolve();
        this.loadCrmTeamObjectives();
      });
  }
}
