<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="content">
  <div class="container">
    <nb-card-header>
      <div class="row">
        <div class="col-md-3">
          <h3>B2B Sales Funnel</h3>
        </div>
        <div class="col-md-6">
          <div class="row">
            <input [(ngModel)]="search" [disabled]="listCrmTeamTrackingDeals.length === 0"
                   class="w-75" nbInput
                   nbTooltip="Tap here to search your project" nbTooltipStatus="primary" placeholder="search Project"
                   type="text">
          </div>
        </div>
        <div class="col-md-3">
          <div>
            <button (click)="exportAsXLSX()"
                    align="right" class="mx-1"
                    nbButton nbTooltip="Click here to export data to Excel" nbTooltipStatus="primary"
                    status="success">
              <i class="fas fa-file-excel text-white"></i>
            </button>

            <button [routerLink]="['/crm/add-crm-team-tracking-deals']" class="mx-1" nbButton

                    nbTooltip="Click here to add new project" nbTooltipStatus="primary"
                    status="primary">
              <nb-icon icon="plus-outline"></nb-icon>
              Add
            </button>

          </div>
        </div>
      </div>

    </nb-card-header>
    <div  *ngIf="!listCrmTeamTrackingDeals || listCrmTeamTrackingDeals.length === 0"  align="center">
      <nb-alert class="w-100 mt-3" outline="danger">No Data Found.</nb-alert>
    </div>

    <div *ngIf="(listCrmTeamTrackingDeals | filter : search).length === 0" [hidden]="listCrmTeamTrackingDeals.length === 0"
         class="">
      <nb-alert class="col-lg-12 mt-3" fullWidth outline="danger">The item searched is not found. Please make sure
        while typing your data
      </nb-alert>
    </div>

      <table class="table  col-md-11" id="htmlData" style="margin-left: auto; margin-right: auto; margin-top: 30px;">
        <thead class="bg-light">
        <tr>
          <th>Prospect</th>
          <th>NOM ENTREPRISE</th>
          <th>Stage</th>
          <th>Valeur (Marché)</th>
          <th>Recette attendue</th>
          <th>Team Member</th>
          <th>Etape Suivante</th>
          <th>Actions</th>

        </tr>
        </thead>
        <tbody>
        <tr style="color: white; font-weight: bold;">
          <td>Total : {{ listCrmTeamTrackingDeals.length }}</td>
          <td></td>
          <td></td>
          <td>{{ formatNumber(totalValeur) }}</td>
          <td>{{ formatNumber(totalRecette) }}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr
          *ngFor="let crmTeamTrackingDeals of listCrmTeamTrackingDeals | filter : search | paginate : {itemsPerPage:pageSize, currentPage: current,totalItems: total}"
          class="col-md-9 col-sm-10">

          <td>
            <div class="d-flex align-items-center">
              <nb-user [name]="crmTeamTrackingDeals.prospect.name" onlyPicture picture="{{crmTeamTrackingDeals.prospect.image}}"></nb-user>
              <div class="ms-3">
                <p class=" mb-1">{{crmTeamTrackingDeals.prospect.name}}</p>
                <p class=" mb-0">{{crmTeamTrackingDeals.emailContact}}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <nb-user [name]="crmTeamTrackingDeals.nomEntreprise.name" onlyPicture picture="{{crmTeamTrackingDeals.nomEntreprise.image}}"></nb-user>
              <div class="ms-3">
                <p class=" mb-1">{{crmTeamTrackingDeals.nomEntreprise.name}}</p>
              </div>
            </div>
          </td>
          <td><p class="fw-normal mb-1">{{crmTeamTrackingDeals.stage.stage}} : {{crmTeamTrackingDeals.stage.probabilite}}</p></td>
          <td><p class="fw-normal mb-1">{{crmTeamTrackingDeals.valeur}}</p></td>
          <td><p class="fw-normal mb-1">{{crmTeamTrackingDeals.recette}}</p></td>
          <td><p class="fw-normal mb-1">{{crmTeamTrackingDeals.teamMember.representantCommercial}}</p></td>
          <td><p class="fw-normal mb-1">{{crmTeamTrackingDeals.etapeSuivante.etape}}</p></td>

          <td>

            <button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" mat-icon-button nbButton
                    shape="round" size="small"
                    status="basic">
              <nb-icon icon="more-horizontal-outline"></nb-icon>
            </button>
            <mat-menu #menu="matMenu" class="bg-white">

              <button mat-menu-item>
                <a
                  [routerLink]="['/crm/details-crm-team-tracking-deals' , crmTeamTrackingDeals.id]"
                  fullWidth nbButton>
                  <span class="text-info">
                  <nb-icon icon="eye-outline"></nb-icon></span> Details
                </a>
              </button>
              <button mat-menu-item>
                <a [routerLink]="['/crm/edit-crm-team-tracking-deals/', crmTeamTrackingDeals.id]"
                   fullWidth nbButton>
               <span class="text-warning">
                        <nb-icon icon="edit-outline"></nb-icon>
                      </span> Edit
                </a>
              </button>

              <button mat-menu-item>
                <a (click)="onArchiveConfirm(crmTeamTrackingDeals , crmTeamTrackingDeals.id)" fullWidth nbButton status="basic">
                  <nb-icon icon="archive-outline"></nb-icon>
                  Archive
                </a>
              </button>


            </mat-menu>
          </td>

        </tr>

        </tbody>
      </table>
      <!-- end col -->
    <pagination-controls (pageChange)="current = $event" align="right"></pagination-controls>

    </div>


    <!-- end row -->

    <!-- end row -->
  </div>
  <!-- container -->
