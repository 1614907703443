<!-- Flip Card Button -->
<div style="width: 100%; margin-bottom: 20px;">
  <button (click)="toggleFlipCard()" nbButton status="info" style="float: right;">
    {{ buttonLabel }}
  </button>
</div>

<nb-flip-card #flipCardComponent>
  <nb-card-front>
    <nb-card>
      <nb-tab tabTitle="Profit" [lazyLoad]="true">
        <div class="chart-container">
          <ngx-profit-chart style="margin-top: 30px" [profitChartData]="profitChartData"></ngx-profit-chart>
        </div>
      </nb-tab>
    </nb-card>
  </nb-card-front>
  <nb-card-back>
    <nb-card>
      <nb-card-header>
        <h4 style="color: #4b88cb; text-align: center;">Sales Funnel Overview</h4>
      </nb-card-header>
      <nb-card-body>
        <table [nbTreeGrid]="dataSource" [nbSort]="dataSource" (sort)="updateSort($event)">

          <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
          <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns"></tr>

          <ng-container *ngFor="let column of defaultColumns; let index = index"
                        [nbTreeGridColumnDef]="column"
                        [showOn]="getShowOn(index)">
            <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(column)" *nbTreeGridHeaderCellDef>
              {{column}}
            </th>
            <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data[column] || '-'}}</td>
          </ng-container>

        </table>
      </nb-card-body>
    </nb-card>
    <nb-card>
      <nb-card-header>
        <h4 style="color: #4b88cb; text-align: center;">Representative with the most sales</h4>
      </nb-card-header>
      <nb-card-body>

        <table [nbTreeGrid]="dataSource2" [nbSort]="dataSource2" (sort)="updateSort($event)">

          <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns2"></tr>
          <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns2"></tr>

          <ng-container *ngFor="let column of defaultColumns2; let index = index"
                        [nbTreeGridColumnDef]="column"
                        [showOn]="getShowOn(index)">
            <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(column)" *nbTreeGridHeaderCellDef>
              {{column}}
            </th>
            <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data[column] || '-'}}</td>
          </ng-container>

        </table>

      </nb-card-body>
    </nb-card>
    <nb-card>
      <nb-card-header>
        <h4 style="color: #4b88cb; text-align: center;">Team Members Objectives and Values</h4>
      </nb-card-header>
      <nb-card-body>
        <table [nbTreeGrid]="dataSource3" [nbSort]="dataSource3" (sort)="updateSort($event)">
          <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns3"></tr>
          <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns3"></tr>
          <ng-container *ngFor="let column of allColumns3; let index = index"
                        [nbTreeGridColumnDef]="column"
                        [showOn]="getShowOn(index)">
            <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(column)" *nbTreeGridHeaderCellDef>
              {{ column | titlecase }}
            </th>
            <td nbTreeGridCell *nbTreeGridCellDef="let row">{{ row.data[column] || '-' }}</td>
          </ng-container>
        </table>
      </nb-card-body>
    </nb-card>
  </nb-card-back>
</nb-flip-card>
