export interface ProfitChart {
  categories: string[];  // Add categories for the chart labels (X-axis)
  recettePrevData: number[];
  recetteGainData: number[];
  objectifData: number[];
}


export abstract class ProfitChartData {
  abstract getProfitChartData(period: string): ProfitChart;
}
