import { Component } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import {CrmStages} from '../../../models/CrmStages';
import {CrmStagesService} from '../../../services/crmStages/crm-stages.service';
import {CrmFollowUpService} from '../../../services/crmFollowUp/crm-follow-up.service';
import {CrmEtapesService} from '../../../services/CrmEtapes/crm-etapes.service';
import {NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService} from "@nebular/theme";

@Component({
  selector: 'ngx-crm-sales-stages',
  templateUrl: './crm-sales-stages.component.html',
  styleUrls: ['./crm-sales-stages.component.scss'],
})
export class CrmSalesStagesComponent {
  // toast configuration
  index = 1;
  destroyByClick = true;
  toastduration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  source: LocalDataSource = new LocalDataSource();
  sourceCrmFollowUp: LocalDataSource = new LocalDataSource();
  sourceCrmEtapes: LocalDataSource = new LocalDataSource();
  crmStages: CrmStages = new CrmStages();
  CrmEtapesSettings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      etape: { title: 'LES ETAPES SUIVANTES', type: 'string' },
    },
  };
  CrmFollowUpSettings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      nbJours: {
        title: 'NOMBRE DE JOURS AVANT LE PROCHAIN RAPPEL',
        type: 'number',
        editor: {
          type: 'input',
          config: {
            validators: {
              required: true,
              pattern: '^[0-9]*$',  // Accepter uniquement des chiffres
            },
          },
        },
      },
    },
  };
  settings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      stage: { title: 'STAGE', type: 'string' },
      probabilite: {
        title: 'PROBABILITE (%)',
        type: 'number',
        valuePrepareFunction: (value) => {
          return value.includes('%') ? value : `${value} %`;        },
        editor: {
          type: 'input',
          config: {
            validators: {
              required: true,
              pattern: '^[0-9]*$',  // Accepter uniquement des chiffres
            },
          },
        },
      },
    },
  };



  constructor(private service: CrmStagesService, private serviceCrmFollowUp: CrmFollowUpService, private serviceCrmEtapes: CrmEtapesService , private toastrService: NbToastrService) {
    // const data = this.service.getData();
    // this.source.load(data);
    this.loadCrmFollowUp();
    this.loadCrmStages();
    this.loadCrmEtapes();
  }

  onCreateConfirm(event): void {
    const newData = event.newData;

    if (!newData.probabilite || isNaN(newData.probabilite)) {
      this.showToast('danger', 'Error', 'Please enter a valid number for Probabilité');
      event.confirm.reject();
      return;
    }

    // Ajouter le symbole % à la probabilité avant de sauvegarder
    newData.probabilite = `${newData.probabilite}%`;

    // Proceed with the creation if both fields are valid
    this.service.addCrmStages(newData).subscribe(
      data => {
        this.loadCrmStages();
        event.confirm.resolve();
      },
      error => {
        this.showToast('danger', 'Error', 'Error adding CRM stages');
        event.confirm.reject();
      },
    );
  }





  loadCrmStages() {
    this.service.findAllCrmStages().subscribe(
      (response: any) => {
        if (Array.isArray(response)) {
          this.source.load(response);
        } else {
          console.error('Expected an array of data:', response);
        }
      },
      error => {
        console.error('Error loading CRM stages:', error);
      },
    );
  }


  onEditConfirm(event): void {
    const newData = event.newData;

    // Valider que "probabilite" est un nombre sans le symbole %
    const probabiliteValue = newData.probabilite.replace('%', ''); // Enlever le symbole %

    if (isNaN(probabiliteValue)) {
      this.showToast('danger', 'Error', 'Please enter a valid number for Probabilité');
      event.confirm.reject();
      return;
    }

    // Ajouter le symbole % à la probabilité avant de sauvegarder
    newData.probabilite = `${probabiliteValue}%`;

    // Sauvegarder les données avec le symbole %
    this.service.updateCrmStages(newData).subscribe(
      data => {
        event.confirm.resolve(data);
        this.source.update(event.data, data);
      },
      error => {
        this.showToast('danger', 'Error', 'Error updating CRM stages');
        event.confirm.reject();
      },
    );
  }


  onDeleteConfirm(event): void {
      this.service.deleteCrmStages(event.data).subscribe(
        data => {
          event.confirm.resolve();
          this.loadCrmStages();
        },
        error => {
          this.showToast('danger', 'Error', 'Error deleting CRM stage');
          event.confirm.reject();
        },
      );
  }


  onCreateCrmFollowUpConfirm(event): void {
    const newData = event.newData;

    // Validation du champ "nbJours" pour s'assurer que c'est un nombre
    if (!newData.nbJours || isNaN(newData.nbJours)) {
      this.showToast('danger', 'Error', 'Please enter a valid number for Nombre de jours');
      event.confirm.reject();
      return;
    }

    // Si la validation est réussie, procéder avec l'ajout
    this.serviceCrmFollowUp.addCrmFollowUp(newData).subscribe(
      data => {
        this.loadCrmFollowUp();
        event.confirm.resolve();
      },
      error => {
        this.showToast('danger', 'Error', 'Error adding CRM follow-up');
        event.confirm.reject();
      },
    );
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.toastduration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }


  loadCrmFollowUp() {
    this.serviceCrmFollowUp.findAllCrmFollowUp().subscribe(
      (response: any) => {
        if (Array.isArray(response)) {
          this.sourceCrmFollowUp.load(response);
        } else {
          console.error('Expected an array of data:', response);
        }
      },
      error => {
        console.error('Error loading CRM follow up:', error);
      },
    );
  }


  onEditCrmFollowUpConfirm(event): void {
    const newData = event.newData;

    // Validation du champ "nbJours" pour s'assurer que c'est un nombre
    if (!newData.nbJours || isNaN(newData.nbJours)) {
      this.showToast('danger', 'Error', 'Please enter a valid number for Nombre de jours');
      event.confirm.reject();
      return;
    }

    // Si toutes les validations sont réussies, procéder à la mise à jour
    this.serviceCrmFollowUp.updateCrmFollowUp(newData).subscribe(
      data => {
        // Confirmer l'édition dans la table et mettre à jour les données affichées
        event.confirm.resolve(data);
        this.sourceCrmFollowUp.update(event.data, data);
        this.showToast('success', 'Success', 'CRM follow-up updated successfully');
      },
      error => {
        this.showToast('danger', 'Error', 'Error updating CRM follow-up');
        event.confirm.reject();
      },
    );
  }

  onDeleteCrmFollowUpConfirm(event): void {
      this.serviceCrmFollowUp.deleteCrmFollowUp(event.data).subscribe(data => {
        event.confirm.resolve();
        this.loadCrmFollowUp();
      });
  }

  onCreateCrmEtapesConfirm(event): void {
    this.serviceCrmEtapes.addCrmEtapes(event.newData).subscribe(
      data => {
        // Mettez à jour la table pour refléter les données du serveur au lieu d'ajouter localement
        this.loadCrmEtapes();  // Rechargez toutes les données depuis le serveur
        event.confirm.resolve();  // Confirmez l'ajout dans la table
      },
      error => {
        console.log('Error adding CRM Etapes:', error);
        event.confirm.reject();
      },
    );
  }



  loadCrmEtapes() {
    this.serviceCrmEtapes.findAllCrmEtapes().subscribe(
      (response: any) => {
        if (Array.isArray(response)) {
          this.sourceCrmEtapes.load(response);
        } else {
          console.error('Expected an array of data:', response);
        }
      },
      error => {
        console.error('Error loading CRM Etapes:', error);
      },
    );
  }


  onEditCrmEtapesConfirm(event): void {

    this.serviceCrmEtapes.updateCrmEtapes(event.newData).subscribe(
      data => {
        // Assurez-vous que data contient l'objet modifié complet
        event.confirm.resolve(data); // Mettez à jour la ligne dans la table
        this.sourceCrmEtapes.update(event.data, data);
      },
      error => {
        console.log('Error updating CRM Etapes:', error);
        event.confirm.reject();
      },
    );
  }
  onDeleteCrmEtapesConfirm(event): void {
      this.serviceCrmEtapes.deleteCrmEtapes(event.data).subscribe(data => {
        event.confirm.resolve();
        this.loadCrmEtapes();
      });
  }
}
